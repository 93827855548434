import * as React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import Bio from '../components/bio';
import Layout from '../components/layout';
import Seo from '../components/seo';

function BlogPostTemplate({ data, location }) {
  const post = data.markdownRemark;
  const { previous, next } = data;
  const featuredImgFluid = post.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData;

  return (
    <Layout location={location}>
      <Seo
        title={`Blog post: ${post.frontmatter.title}`}
        description={post.frontmatter.description || post.excerpt}
      />
      <nav className="breadcrumb">
        <Link to="/blog" rel="prev">
          All blog posts
        </Link>
      </nav>
      <article
        className="article-container"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{post.frontmatter.title}</h1>
          <p>{post.frontmatter.date}</p>
          <Bio />
        </header>
        {featuredImgFluid && (
        <div className="featured-image">
          <GatsbyImage image={featuredImgFluid} />
        </div>
        )}
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
      </article>
      <hr />
      <nav className="blog-post-nav">
        <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            listStyle: 'none',
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                ←
                {' '}
                {previous.frontmatter.title}
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                {next.frontmatter.title}
                {' '}
                →
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  );
}

export default BlogPostTemplate;

export const pageQuery = graphql`query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
  site {
    siteMetadata {
      title
    }
  }
  markdownRemark(id: {eq: $id}) {
    id
    excerpt(pruneLength: 160)
    html
    frontmatter {
      title
      date(formatString: "MMMM DD, YYYY")
      description
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 820, layout: CONSTRAINED)
        }
      }
    }
  }
  previous: markdownRemark(id: {eq: $previousPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
  next: markdownRemark(id: {eq: $nextPostId}) {
    fields {
      slug
    }
    frontmatter {
      title
    }
  }
}
`;
